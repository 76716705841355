import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: "181406b2-b037-4204-86fd-5cb5a5a4d4cb",
    authority: "https://login.microsoftonline.com/e6b941ea-79ca-4381-9570-6257fa13d3e4",  // Replace with your tenant ID
    // redirectUri: "http://localhost:8080/admin/portal/sso_az_callback"  // Backend callback
    redirectUri: "http://localhost:4200/auth/sso_az_callback"  // Backend callback
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};
console.log("===== init Microsoft Configuration ====")
export const msalInstance = new PublicClientApplication(msalConfig);
