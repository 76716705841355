import api from "src/api/Api";
import urls from "src/api/Urls";

export const getEndpoints = (params) => {
    return api.get(urls.endpoints, { params: params });
}

export const exportNumbers = (params) => {
    return api.get(urls.export_numbers, { params: params });
}

export const getSMSEndpoints = (params) => {
    return api.get(urls.sms_endpoints, { params: params });
}

export const updateVoiceEndpoint = (id, payload) => {
    return api.patch(urls.update_voice_endpoint(id), payload);
}

export const addVoiceEndpoint = (payload) => {
    return api.post(urls.add_voice_endpoint, payload);
}


export const updateSMSEndpoint = (id, payload) => {
    return api.patch(urls.update_sms_interconnection(id), payload);
}

export const deleteVoiceEndpoint = (id) => {
    return api.delete(urls.update_voice_endpoint(id));
}

export const deleteSMSEndpoint = (id) => {
    return api.delete(urls.update_sms_endpoint(id));
}

export const addSMSEndpoint = (payload) => {
    return api.post(urls.add_sms_interconnection, payload);
}

export const buyNumber = (payload) => {
    return api.post(urls.buy_number, payload);
}


export const addNumber = (payload) => {
    return api.post(urls.add_number, payload);
}

export const listNumbers = (params) => {
    return api.get(urls.list_numbers, { params: params });
}

export const numberDetail = (params) => {
    return api.get(urls.number_detail, { params: params });
}

export const scheduleRequest = (payload) => {
    return api.post(urls.schedule_request,  payload);
}

export const cancelRequest = (payload) => {
    return api.post(urls.cancel_request,  payload);
}

export const editRequest = (payload) => {
    return api.patch(urls.cancel_request,  payload);
}

export const listRouting = (params) => {
    return api.get(urls.list_routing, { params: params });
}

export const listRoutingSMS = (params) => {
    return api.get(urls.list_routing_sms, { params: params });
}

export const listInterconnection = (params) => {
    return api.get(urls.list_interconnection, { params: params });
}

export const listSMSInterconnection = (params) => {
    return api.get(urls.list_sms_interconnection, { params: params });
}

export const searchNumbers = (params) => {
    return api.get(urls.search_numbers, { params: params });
}

export const deallocateNumber = (payload) => {
    return api.post(urls.deallocate_number, payload);
}

export const updateNumber = (payload) => {
    return api.post(urls.update_number_translation, payload );
}

export const getCountries = (params) => {
    return api.get(urls.number_enabled_countries, { params: params });
}


export const getDIDTypes = (params) => {
    return api.get(urls.did_types_with_filter, { params: params });
}

export const getCapabilities = (params) => {
    return api.get(urls.capabilities_with_filter, { params: params });
}

export const getProviderCodes = (params) => {
    return api.get(urls.get_provider_codes, { params: params });
}

export const getBuyNumbers = (params) => {
    return api.get(urls.get_portal_numbers, { params: params });
}

export const uploadFilesBuyNumber = (payload, onUploadProgress) => {
    return api.post(urls.upload_files_buy_number, payload, onUploadProgress);
}

export const updateRoutingVoice = (payload) => {
    return api.post(urls.update_routing_voice, payload );
}

export const updateRoutingSMS = (payload) => {
    return api.post(urls.update_routing_sms, payload );
}

export const getRejectedDocuments = (params) => {
    return api.get(urls.get_rejected_documents, { params: params } );
}

export const resubmitDocs = (payload) => {
    return api.post(urls.submit_rejected_documents, payload );
}


export const numberRequest = (payload) => {
    return api.post(urls.number_request, payload );
}

export const updateNumberRequest = (payload) => {
    return api.post(urls.update_number_request, payload );
}