import { useTranslation } from 'react-i18next';
import { createContext, useReducer, useContext } from "react";
import palette from "src/theme/palette";
import Reducer from './Reducer'

//const user = JSON.parse(localStorage.getItem("user"));
const admin = localStorage.getItem("admin");
const token = localStorage.getItem("token");
const refresh_token = localStorage.getItem("refresh_token");
const language = localStorage.getItem("language");
const supported_languages = JSON.parse(localStorage.getItem("supported_languages"));
const roles = JSON.parse(localStorage.getItem("roles"));
const meta = JSON.parse(localStorage.getItem("meta"));
const detail = JSON.parse(localStorage.getItem("detail"));
const services = JSON.parse(localStorage.getItem("services"));

/*
        token: loginResponse.data.access_token,
            expires_at: loginResponse.data.expires_at,
            refresh_token: loginResponse.data.refresh_token,
            roles: loginResponse.data.roles,
            meta: loginResponse.data.meta,
            admin: false,
*/

const StoreContext = createContext(null);

const Store = ({ children, initialStateData = {} }) => {
    const { t } = useTranslation();
    const initialState = {
        admin: admin ? admin : null,
        token: token ? token : null,
        roles : roles ? roles : null,
        meta : meta ? meta : null,
        detail : detail ? detail : null,
        portalSettings: { services: services },
        errorMessage: null,
        palette: initialStateData.colors,
        defaultPalette: { ...initialStateData.colors },
        selectedPalette: "default",
        palettes: {
            default: { ...initialStateData.colors, label: t('default') },
        },
        supported_languages: supported_languages ? supported_languages : [],
        language: language ? language : "en-US",
        styleUpdatedOnState: false,
        fontFamily: initialStateData.fontFamily,
        portalFeatures: initialStateData.portalFeatures,
        ...initialStateData
    };

    const [store, dispatch] = useReducer(Reducer, initialState);

    if (initialState.styleUpdatedOnState && !(store.manualUpdateTriggeredForPalette)) {
        dispatch({
            type: "UPDATE_INITIAL_PALETTE_LOGO",
            payload: {
                palette: initialStateData.colors,
                manualUpdateTriggeredForPalette: true,
                logo: initialState.logo,
                backgroundImage: initialState.backgroundImage,
                fontFamily: initialState.fontFamily,
                portalFeatures: initialState.portalFeatures,
            }});
    }
    return (
        <StoreContext.Provider value={[store, dispatch]}>
            {children}
        </StoreContext.Provider>
    )
};

export const useStore = () => useContext(StoreContext);
export default Store;
