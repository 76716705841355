import { useState, useEffect } from 'react';
// store
import Store from './store/Store';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
// axios
import api from './api/Api'
import {SiteConfigService} from './api/services';
// utils
import { setFavIcon } from 'src/utils/Util';
import BaseSnackbar from "./components/BaseSnackbar";
import {SnackbarProvider} from "./store/SnackbarContext";
import WithAxios from "./store/WithAxios";

// MSAL
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msalConfig'; // Add your MSAL configuration here

// ----------------------------------------------------------------------

const getApiUrl = () => {
  var request = new XMLHttpRequest();

  request.open("GET", document.location.origin, false);
  request.send();

  if (request.readyState === 4){
      let islocal = document.location.origin.includes('127.0.0.1') || document.location.origin.includes('localhost');
      return islocal ? 'http://localhost:8080/admin' : document.location.origin +'/admin';
    }
}
const baseUrl = getApiUrl();
api.defaults.baseURL = baseUrl;


export default function App() {
    const [stateData, setStateData] = useState();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [message, setMessage] = useState("");
    const findColorAndChange = (data, from) => {
        return data.colors.find(color => color.name === from)?.value;
    }

  useEffect(() => {
    const fetchStyle = async () => {

       SiteConfigService.getStyle()
          .then((response) => {
            const style =   response.data.data.style;
      
     
            let portalSettings = response.data;
            
              let styleResponse = style;
              let accessManagementResponse = portalSettings.data.access_management;

              //STYLE MAPPING
              styleResponse["style"] = {
                  "palettes": {
                      "default": {
                          "grey": {
                              "0": "#FFFFFF",
                              "100": "#F9FAFB",
                              "200": "#F4F6F8",
                              "300": "#DFE3E8",
                              "400": "#C4CDD5",
                              "500": "#919EAB",
                              "600": "#637381",
                              "700": "#454F5B",
                              "800": "#212B36",
                              "900": "#161C24",
                              "5008": "rgba(145, 158, 171, 0.08)",
                              "50012": "rgba(145, 158, 171, 0.12)",
                              "50016": "rgba(145, 158, 171, 0.16)",
                              "50024": "rgba(145, 158, 171, 0.24)",
                              "50032": "rgba(145, 158, 171, 0.32)",
                              "50048": "rgba(145, 158, 171, 0.48)",
                              "50056": "rgba(145, 158, 171, 0.56)",
                              "50080": "rgba(145, 158, 171, 0.8)"
                          },
                          "info": {
                              "dark": "#3b9cf7",
                              "main": "#6cb5f9",
                              "light": "#9dcefb",
                              "darker": "#0869c4",
                              "lighter": "#ffffff",
                              "darkmost": "#064f93",
                              "lightmost": "#ffffff",
                              "contrastText": "#fff"
                          },
                          "text": {
                              "primary": "#161C24",
                              "disabled": "#919EAB",
                              "secondary": "#637381"
                          },
                          "error": {
                              "dark": "#c40e08",
                              "main": "#f24870",
                              "light": "#f7413b",
                              "darker": "#620704",
                              "lighter": "#fba09d",
                              "darkmost": "#310402",
                              "lightmost": "#ffffff",
                              "contrastText": "#fff"
                          },
                          "label": "Default",
                          "action": {
                              "focus": "rgba(145, 158, 171, 0.24)",
                              "hover": "rgba(145, 158, 171, 0.08)",
                              "active": "#637381",
                              "disabled": "rgba(145, 158, 171, 0.8)",
                              "selected": "rgba(145, 158, 171, 0.16)",
                              "hoverOpacity": 0.08,
                              "disabledOpacity": 0.48,
                              "disabledBackground": "rgba(145, 158, 171, 0.24)"
                          },
                          "common": {
                              "black": "#000",
                              "white": "#fff"
                          },
                          "divider": "rgba(145, 158, 171, 0.24)",
                          "primary": {
                              //Button hover
                              "dark": findColorAndChange(styleResponse, "--main-text-color"),
                              //BUTTON COLORS ETC
                              "main": findColorAndChange(styleResponse, "--color-search"),
                              //PAGE TITLE
                              "light": findColorAndChange(styleResponse, "--main-text-color"),
                              "darker": "#5c7a0d",
                              "lighter": "#e9f8c1",
                              "darkmost": "#3a4c08",
                              "lightmost": "#ffffff",
                              "contrastText": "#ffffff"
                          },
                          "success": {
                              "dark": "#34e000",
                              //CHIP COLOR SUCCESSFUL
                              "main": findColorAndChange(styleResponse, "--color-success"),
                              "light": "#72ff47",
                              "darker": "#1d7a00",
                              "lighter": "#c0ffad",
                              "darkmost": "#114700",
                              "lightmost": "#ffffff",
                              "contrastText": "#ffffff"
                          },
                          "warning": {
                              "dark": "#cc9900",
                              "main": "#fbc146",
                              "light": "#ffcc33",
                              "darker": "#664c00",
                              "lighter": "#ffe599",
                              "darkmost": "#332600",
                              "lightmost": "#ffffff",
                              "contrastText": "#212B36"
                          },
                          "gradients": {
                              "info": "linear-gradient(to bottom, #74CAFF, #1890FF)",
                              "error": "linear-gradient(to bottom, #FFA48D, #FF4842)",
                              "primary": "linear-gradient(to bottom, #232f58, #0A1845)",
                              "success": "linear-gradient(to bottom, #AAF27F, #54D62C)",
                              "warning": "linear-gradient(to bottom, #FFE16A, #FFC107)",
                              "background": "linear-gradient(to bottom, #F3F9FD, #F3F9FD)"
                          },
                          "secondary": {
                              //TEXTCOLOR
                              "dark": findColorAndChange(styleResponse, "--main-text-color"),
                              // LEFT PANEL ETC
                              "main": findColorAndChange(styleResponse, "--main-text-color"),
                              "main2": findColorAndChange(styleResponse, "--secondary-color"),
                              "light": "#0aa937",
                              "darker": "#000000",
                              // LANDING VIEW selected BUTTONS
                              "lighter": findColorAndChange(styleResponse, "--secondary-color"),
                              "darkmost": "#000000",
                              // LANDING VIEW SELECT BUTTONS
                              "lightmost": findColorAndChange(styleResponse, "--main-text-color"),
                              "contrastText": "#f5fce6"
                          },
                          "background": {
                              "dark": "#cedad0",
                              //PAGE BGCOLOR
                              "main": findColorAndChange(styleResponse, "--main-background-color"),
                              "light": "#ffffff",
                              "paper": "#fff",
                              "darker": "#a8a8a8",
                              "default": "#fff",
                              "lighter": "#ffffff",
                              "neutral": "#F4F6F8",
                              "darkmost": "#8f8f8f",
                              "lightmost": "#ffffff",
                              //PAGE CONTENT TEXT
                              "contrastText": findColorAndChange(styleResponse,"--main-text-color")
                          }
                      }
                  },
                  "selectedPalette": "default"
              };

              style.colors = style.colors.reduce((acc, item) => {
                acc[item.name] = item.value;
                return acc;
              }, {});

              const initialStateData = {
                  colors: style.colors,
                  palette: styleResponse.style.palettes[styleResponse.style.selectedPalette ? styleResponse.style.selectedPalette : "default"],
                  palettes: styleResponse.style.palettes,
                  selectedPalette: styleResponse.style.selectedPalette,
                  logo: styleResponse.logo,
                  backgroundImage: styleResponse.background_image,
                  supported_languages: styleResponse.supported_languages,
                  styleUpdatedOnState: true,
                  accessManagement: accessManagementResponse,
                  portalFeatures: portalSettings.data.portal_features,
                  fontFamily: style.colors["--font-family"] || "Lato"
              };
              setStateData(initialStateData);
              setFavIcon(styleResponse.logo);
          })
          .catch((err) => {
              setMessage("Error occurred while getting settings");
              setOpenSnackbar(true);
          })
          .finally(() => {
          })
      }
    fetchStyle().catch((err) => {
      console.log(err);
    });
  }, [])



  return (
    <MsalProvider instance={msalInstance}>
      <SnackbarProvider>
        <Store initialStateData={stateData}>
          <ThemeConfig>
            <ScrollToTop />
            <BaseSnackbar open={openSnackbar} message={message} setOpen={setOpenSnackbar} />
            <WithAxios setMessage={setMessage} setOpenSnackbar={setOpenSnackbar}>
              <Router />
            </WithAxios>
          </ThemeConfig>
        </Store>
      </SnackbarProvider>
    </MsalProvider>
  );

}


